import React, { FC } from "react";
import { PageHeaderProps } from "./../generated-types";
import { sc, useSitecoreContext } from "~/foundation/Jss";
import { PageHeaderBase, PageHeaderImageBase, PageHeaderLinksBase } from "~/foundation/SitecoreContent/generated-types";
import { Ribbon } from "~/foundation/Components/Ribbon";
import { Box, Flex, Heading, Text as ChakraText } from "@chakra-ui/react";
import { ContentWrapper } from "~/foundation/Components/ContentWrapper";
import { PageCenteredBase, PageThemeBase } from "~/foundation/Theme/generated-types";
import { usePageTheme } from "~/foundation/Theme/enhancers/usePageTheme";
import { BreakingNews } from "~/foundation/Components/BreakingNews";
import { SiteNavigation } from "~/foundation/Components/SiteNavigation";

const PageHeader: FC<PageHeaderProps> = ({ rendering }) => {
	const { sitecoreContext } = useSitecoreContext<PageHeaderBase & PageHeaderImageBase & PageHeaderLinksBase & PageThemeBase & PageCenteredBase>()
	//const showImage = sitecoreContext.route?.fields?.image?.value?.src || sitecoreContext.pageEditing;
	const centeredHeader = sitecoreContext.route?.fields?.showPageHeaderCentered?.value || false;
	const showLinkSection = sitecoreContext.route?.fields?.featuredPrimaryLink || sitecoreContext.route?.fields?.featuredSecondaryLink || sitecoreContext.pageEditing;
	const theme = usePageTheme(sitecoreContext);
	const pageHeaderSpacingNone = sitecoreContext.route?.fields?.pageHeaderSpacing?.value === "none";

	return (
		<Ribbon
			pt={["var(--chakra-sizes-headerHeightBase)", null, null, "var(--chakra-sizes-headerHeightLG)"]}
			bg={theme.colors.ribbon.bg}
			color={theme.colors.ribbon.color}
			textAlign={centeredHeader ? "center" : "inherit"}
		>
			<BreakingNews sitecoreContext={sitecoreContext} />
			<SiteNavigation sitecoreContext={sitecoreContext} />
			<Box pt={["0", null, "10"]} pb={["0", null, ".1rem"]}>
				<sc.Placeholder name={rendering.placeholderNames.breadcrumb_navigation} rendering={rendering}/>
			</Box>
			<ContentWrapper
				position="relative"
				pt={["8", null, "12", "12", "12"]}
				pb={pageHeaderSpacingNone ? ["0", null, "0", null, "0"] : ["var(--chakra-sizes-modulePY__SM)", null, "var(--chakra-sizes-modulePY__MD)", null, "var(--chakra-sizes-modulePY__XL)"]}
			>
				<Flex alignItems="center" justifyContent={centeredHeader ? "center" : "start"} direction={["column", null, "row"]}>
					<Box flexBasis="66.666%">
						{(sitecoreContext.route?.fields?.trumpet?.value || sitecoreContext.pageEditing) &&
							<Box mb={centeredHeader ? ["8", null, "6"] : ["4", null, "6"]}>
								<ChakraText variant="overline">
									<sc.Text field={sitecoreContext.route?.fields?.trumpet} />
								</ChakraText>
							</Box>
						}
						{(sitecoreContext.route?.fields?.headline?.value || sitecoreContext.pageEditing) &&
							<Box>
								<Heading as="h1" size="xxl">
									<sc.Text field={sitecoreContext.route?.fields?.headline}/>
								</Heading>
							</Box>
						}
						{(sitecoreContext.route?.fields?.description?.value || sitecoreContext.pageEditing) &&
							<ChakraText
								as="span"
								size={centeredHeader ? "body" : "xs"}
								mt={centeredHeader ? "8" : ["4", null, "8"]}
								maxW={centeredHeader ? "38.875rem" : "33.75rem"}
								mx={centeredHeader ? "auto" : "0"}
								display="block"
								sx={{
									'p+p': {
										mt: '4',
									}
								}}>
								<sc.RichText field={sitecoreContext.route?.fields?.description} />
							</ChakraText>
						}
						{showLinkSection &&
						<Flex wrap="wrap" direction={["column", null, "row"]} justifyContent={centeredHeader ? "center" : "start"}>
							<>
								{(sitecoreContext.route?.fields?.featuredPrimaryLink?.value.href || sitecoreContext.pageEditing) &&
									<Box me={centeredHeader ? [null, null, "12"] : [null, null, "16"]} mt={centeredHeader ? "14" : ["12", null, "8"]}>
										<sc.Link
											field={sitecoreContext.route?.fields?.featuredPrimaryLink}
											variant="primary"
											size="md"
											color={theme.colors.ribbon.linkColor} />
									</Box>
								}
								{(sitecoreContext.route?.fields?.featuredSecondaryLink?.value.href || sitecoreContext.pageEditing) &&
									<Box mt={centeredHeader ? ["8", null, "14"] : ["12", null, "8"]} display="flex" justifyContent={centeredHeader ? "center" : "start"}>
										<sc.Link
											field={sitecoreContext.route?.fields?.featuredSecondaryLink}
											variant="secondary"
											size="md"
											color={theme.colors.ribbon.linkColor}
										/>
									</Box>
								}
							</>
						</Flex>
						}
					</Box>
					{/*<Box flexBasis={["50%"]} w="100%">*/}
					{/*	{showImage &&*/}
					{/*		<Box borderRadius="lg" borderBottomRightRadius="0" overflow="hidden" ms={['0', null, "16", null, '6.875rem']} mt={["8", null, "0"]}>*/}
					{/*			<AspectRatio ratio={16 / 9} sx={*/}
					{/*				sitecoreContext.pageEditing ? {} : {*/}
					{/*					'&:after': {*/}
					{/*						content: `""`,*/}
					{/*						position: 'absolute',*/}
					{/*						top: "0",*/}
					{/*						bgColor: 'blackAlpha.400',*/}
					{/*						h: '100%',*/}
					{/*						w: '100%',*/}
					{/*					}*/}
					{/*				}}>*/}
					{/*				<sc.AdvanceImage*/}
					{/*					fields={sitecoreContext.route?.fields}*/}
					{/*					itemId={sitecoreContext.itemId}*/}
					{/*					fieldName="image"*/}
					{/*					srcSet={[imageSizes.pageHeader.md, imageSizes.pageHeader.lg, imageSizes.pageHeader.xl]}*/}
					{/*					sizes="(min-width: 768px) 260px, (min-width: 1440px) 482px, (min-width: 1760px) 760px, 100vw"*/}
					{/*					sx={{ position: "absolute", objectFit: "cover", objectPosition: "center center", minWidth: "100%", minHeight: "100%" }}*/}
					{/*					lazy={false}*/}
					{/*				/>*/}
					{/*			</AspectRatio>*/}
					{/*		</Box>*/}
					{/*	}*/}
					{/*</Box>*/}
				</Flex>
			</ContentWrapper>
		</Ribbon>
	);
};

export default PageHeader;